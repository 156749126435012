var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('AppHeader'),_c('v-divider'),_c('v-layout',{attrs:{"wrap":"","justify-center":"","fill-height":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg2":"","hidden-sm-and-down":""}},[_c('v-navigation-drawer',{staticClass:"fixedSidebar",attrs:{"clipped":"","width":"100%","height":"100vh","permanent":"","color":"#fff","src":require("./../../assets/images/bg4.png"),"overlay-color":"green"},model:{value:(_vm.sideNav),callback:function ($$v) {_vm.sideNav=$$v},expression:"sideNav"}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_vm._l((_vm.navItems),function(item,i){return [_c('v-flex',{key:i,attrs:{"xs12":"","text-center":"","py-2":"","text-uppercase":"","align-self-center":""}},[_c('v-layout',{staticClass:"text-none",attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","px-0":"","pl-2":"","align-self-center":"","text-left":""}},[_c('router-link',{staticStyle:{"text-decoration":"none"},attrs:{"to":item.route}},[_c('v-icon',[_vm._v(_vm._s(item.icon))]),_c('span',{staticStyle:{"font-size":"15px","color":"white","letter-spacing":"3px","cursor":"pointer"},style:(_vm.$route.path == item.route
                          ? {
                              'font-family': 'poppinsbold',
                            }
                          : {
                              'font-family': 'poppinslight',
                            })},[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1),_c('v-flex',{attrs:{"xs12":"","pt-2":"","px-4":""}},[_c('div',{staticStyle:{"border-bottom":"1px solid #ffffff25"}})])],1)],1)]})],2)],1)],1),_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg10":"","pt-12":"","pt-lg-0":""}},[_c('v-layout',{style:(_vm.$route.name == 'Dashboard'
            ? 'background-color: #FFFFFF'
            : 'background-color: #fff9c4'),attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","pa-0":""}},[_c('v-card',{attrs:{"min-height":"100vh","color":_vm.$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF',"flat":_vm.$route.name == 'Dashboard' ? true : true}},[_c('v-layout',{attrs:{"wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","pa-4":""}},[_c('router-view')],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }