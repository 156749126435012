<template>
  <div>
    <AppHeader />
    <v-divider></v-divider>
    <v-layout wrap justify-center fill-height>
      <v-flex xs12 sm12 md12 lg2 hidden-sm-and-down>
        <v-navigation-drawer
          class="fixedSidebar"
          v-model="sideNav"
          clipped
          width="100%"
          height="100vh"
          permanent
          color="#fff"
            src="./../../assets/images/bg4.png"
          overlay-color="green"
        >
          <v-layout wrap justify-center>
            <!-- <v-flex align-self-center v-for="(item, i) in navItems" :key="i">
              <div v-if="!item.dropdown">
                <v-btn
                  :style="
                    $route.name == item.name && item.name != 'HostelWarden'
                      ? 'border-bottom:2px solid white;padding-bottom:6px'
                      : ''
                  "
                  class="btn nsbold"
                  @click="$router.push({ path: item.route })"
                  text
                  tile
                >
                  <span
                    :style="
                      $route.path == item.route
                        ? {
                            'font-family': 'poppinsbold',
                          }
                        : {
                            'font-family': 'poppinslight',
                          }
                    "
                    style="
                      font-size: 15px;
                      color: BLACK;
                      letter-spacing: 3px;
                      cursor: pointer;
                    "
                  >
                    {{ item.title }}
                  </span>
                </v-btn>
              </div>
              <div v-else>
                <v-menu open-on-hover bottom tile offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      :style="
                        $route.name == item.name
                          ? 'border-bottom:2px solid white;padding-bottom:6px'
                          : ''
                      "
                      class="btn nsbold"
                      text
                      tile
                    >
                      <span
                        :style="
                          $route.path == '/HostelWarden'
                            ? 'color:#000000'
                            : 'color:#ffffff'
                        "
                      >
                        {{ item.title
                        }}<v-icon x-small style="color: white"
                          >mdi-chevron-down</v-icon
                        >
                      </span>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item v-for="(item2, i2) in item.dropdown" :key="i2">
                      <v-list-item-title>
                        <v-btn
                          class="nsbold"
                          @click="$router.push({ path: item2.route })"
                          text
                          style="text-transform: none"
                          tile
                        >
                          <span>
                            {{ item2.title }}
                          </span>
                        </v-btn>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-flex> -->
            <template v-for="(item, i) in navItems">
              <v-flex
                xs12
                :key="i"
                text-center
                py-2
                text-uppercase
                align-self-center
              >
                <v-layout wrap justify-center class="text-none">
                  <v-flex xs12 px-0 pl-2 align-self-center text-left>
                    <router-link style="text-decoration: none" :to="item.route">
                      <v-icon>{{ item.icon }}</v-icon>
                      <span
                        :style="
                          $route.path == item.route
                            ? {
                                'font-family': 'poppinsbold',
                              }
                            : {
                                'font-family': 'poppinslight',
                              }
                        "
                        style="
                          font-size: 15px;
                          color: white;
                          letter-spacing: 3px;
                          cursor: pointer;
                        "
                      >
                        {{ item.name }}
                      </span>
                    </router-link>
                  </v-flex>
                  <v-flex xs12 pt-2 px-4>
                    <div style="border-bottom: 1px solid #ffffff25"></div>
                  </v-flex>
                </v-layout>
              </v-flex>
            </template>
          </v-layout>

          <!-- <v-layout wrap justify-center pb-10>
            <v-flex xs12 py-2 px-3 text-none align-self-center text-left>
              <v-list dense light>
                <v-list-group no-action :value="false" color="#FDAC53">
                  <template v-slot:activator>
                    <span
                      style="
                        font-size: 15px;
                        color: BLACK;
                        letter-spacing: 3px;
                        cursor: pointer;
                      "
                      >Static</span
                    >
                  </template>
                  <v-flex xs12 py-2>
                    <div style="border-bottom: 1px solid #ffffff25"></div>
                  </v-flex>
                  <template v-for="(item, i) in stat">
                    <v-flex xs12 text-left :key="i">
                      <v-layout wrap justify-center>
                        <v-flex xs12>
                          <router-link :to="item.to">
                            <v-icon>{{ item.icon }}</v-icon>
                            <span
                              :style="
                                $route.path == item.route
                                  ? {
                                      'font-family': 'poppinsbold',
                                    }
                                  : {
                                      'font-family': 'poppinslight',
                                    }
                              "
                              style="
                                font-size: 15px;
                                color: BLACK;
                                letter-spacing: 2px;
                                cursor: pointer;
                              "
                            >
                              {{ item.name }}
                            </span>
                          </router-link>
                        </v-flex>
                        <v-flex xs12 py-2 px-4>
                          <div style="border-bottom: 1px solid #ffffff25"></div>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </template>
                </v-list-group>
              </v-list>
            </v-flex>
          </v-layout> -->
        </v-navigation-drawer>
      </v-flex>
      <v-flex xs12 sm12 md12 lg10 pt-12 pt-lg-0>
        <v-layout
          wrap
          justify-center
          :style="
            $route.name == 'Dashboard'
              ? 'background-color: #FFFFFF'
              : 'background-color: #fff9c4'
          "
        >
          <v-flex xs12 pa-0>
            <v-card
              min-height="100vh"
              :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
              :flat="$route.name == 'Dashboard' ? true : true"
            >
              <v-layout wrap justify-center>
                <v-flex xs12 pa-4>
                  <router-view></router-view>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import AppHeader from "@/components/Common/appHeader";
export default {
  components: {
    AppHeader,
  },
  data() {
    return {
      sideNav: true,
      userNavItems: [
        { name: "Dashboard", route: "/appDashboard" },
        { name: "Home", route: "/home" },
        { name: "Events", route: "/EventsPage" },
        { name: "Add Events", route: "/addEvent" },
        { name: "Users", route: "/UserListPage" },
        { name: "Contacts", route: "/subCategory"}
      ],
      userNavItems2: [
        {
          title: "Dashboard",
          route: "/appDashboard",
          name: "appDashboard",
        },
        {
          title: "Home",
          route: "/home",
          name: "home",
          dropdown: [
            {
              title: "New Outpass",
              route: "/generateOutpass",
              name: "generateOutpass",
            },
          ],
        },
        {
          title: "Events",
          route: "/EventsPage",
          name: "EventsPage",
        },
        {
          title: "Users",
          route: "/subCategory",
          name: "subCategory",
          dropdown: [
            {
              title: "Attendance Report",
              route: "/attendanceReport",
              name: "attendanceReport",
            },
          ],
        },
        {
          title: "Contacts",
          route: "/Contacts",
          name: "Contacts",
        },
      ],
    };
  },
  computed: {
    // appType() {
    //   return this.$store.state.userType;
    // },
    navItems() {
      return this.userNavItems;
    },
  },
};
</script>
<style>
@media only screen and (min-device-width: 767px) {
  .fixedSidebar {
    /* position: fixed; */

    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 50;
  }
}
@media only screen and (min-device-width: 360px) and (max-device-width: 640px) {
  .fixedSidebar {
    display: none !important;
  }
}
</style>