<template>
  <div id="appNavbar">
    <v-navigation-drawer v-model="sideNav" fixed temporary color="#fffcf5">
      <v-layout wrap justify-center>
        <v-flex
          xs12
          v-for="(item, i) in appNavItems"
          :key="i"
          text-center
          pa-2
          pl-0
          text-none
          align-self-center
        >
          <v-layout wrap justify-center>
            <v-flex xs12 px-0 pl-2 align-self-center text-left>
              <router-link style="text-decoration: none" :to="item.route">
                <v-icon>{{ item.icon }}</v-icon>
                <span
                  :style="
                    $route.path == item.route
                      ? {
                          'font-family': 'poppinsbold',
                        }
                      : {
                          'font-family': 'poppinslight',
                        }
                  "
                  style="
                    font-size: 15px;
                    color: black;
                    letter-spacing: 3px;
                    cursor: pointer;
                  "
                >
                  {{ item.name }}
                </span>
              </router-link>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-navigation-drawer>

    <v-app-bar
      app
      dark
      color="#005F32"
      dense
      flat
      height="50px"
      class="hidden-lg-and-up"
    >
      <v-app-bar-nav-icon @click.native="sideNav = !sideNav">
        <v-icon color="white">mdi-menu</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>
        <router-link style="text-decoration: none" to="/">
          <v-layout wrap justify-center>
            <v-flex xs12>
              <span
                style="
                  font-family: poppinsbold;
                  font-size: 20px;
                  cursor: pointer;
                  color: #FFFFFF;
                "
              >
                WTI media Portal
              </span>
            </v-flex>
          </v-layout>
        </router-link>
      </v-toolbar-title>
    </v-app-bar>
    <v-app-bar
      color="#005F32"
      elevation="0"
      height="70px"
      class="hidden-md-and-down"
    >
      <v-layout wrap justify-center fill-height>
        <v-flex xs1 pl-7 pt-1 text-center>
          <v-img width="60px" src="../../assets/images/logo.png"></v-img>
        </v-flex>
        <v-flex xs2 align-self-center>
          <router-link style="text-decoration: none" to="/">
            <span
              style="
                font-family: poppinsbold;
                font-size: 20px;
                cursor: pointer;
                color: #FFFFFF;
              "
            >
           WTI Media portal
            </span>
          </router-link>
        </v-flex>
        <v-spacer></v-spacer>
        <v-flex xs6 lg1 xl1 px-4 text-center align-self-center>
          <AccountMenu />
        </v-flex>
      </v-layout>
    </v-app-bar>
  </div>
</template>
<script>
import AccountMenu from "@/components/Common/accountMenu";
export default {
  components: {
    AccountMenu,
  },
  data() {
    return {
      sideNav: false,
      navItems: [
        { name: "Dashboard", route: "/appDashboard" },
        { name: "Home", route: "/home" },
        { name: "Events", route: "/category" },
        { name: "Users", route: "/subCategory" },
        // { name: "Product", route: "/product" },
        // { name: "Contact Category", route: "/contactCategory" },
        // { name: "Contact List", route: "/contactList" },
        // { name: "About Us", route: "/about" },
        // { name: "Services", route: "/Services" },
        // { name: "Social Medias", route: "/socialMedias" },
        // { name: "Projects", route: "/Projects" },
        // { name: "Interns Approval", route: "/InternsApproval" },
        // { name: "Interns", route: "/Interns" },
        // { name: "Intern Batch", route: "/InternBatch" },
        // { name: "Intern Projects", route: "/InternProjects" },
        ],
    };
  },
  computed: {
    appUser() {
      return null;
    },
    appLogin() {
      return false;
    },
    appType() {
      return this.$store.state.userType;
    },
    appNavItems() {
      return this.navItems;
    },
  },
};
</script>
<style>
input::-webkit-input-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: poppinsregular !important;
}
input::-moz-placeholder {
  color: #c5c5c5 !important;
  font-size: 14px !important;
  font-family: poppinsregular !important;
}
.searchBox .v-input__control {
  min-height: 10px !important;
}
.searchBox .v-input__control .v-input__slot {
  padding: 0px !important;
}
</style>